<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <FormButtonGroup align="end" spaced>
          <FormButton text="Novo usuário" type="primario" @click="onNew" />
        </FormButtonGroup>
      </template>
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        sortBy="nome"
        :sortDesc="false"
        ref="datatable"
        search
      >
        <template v-slot:ativo="{ col }">
          <Chip v-if="col" color="green" text="Sim" :small="true" />
          <Chip v-else color="cyan" text="Não" :small="true" />
        </template>
        <template v-slot:itens_menu="{ row }">
          <span v-if="getUsuarioId == row.idusuario">
            Somente <b>outro</b> administrador pode configurar as suas
            permissões
          </span>
          <FormButton
            v-else
            outlined
            text="Configurar permissões"
            color="primario"
            @click="onDialogPermission(row)"
          />
        </template>
        <template v-slot:idusuario="{ row }">
          <v-icon
            v-if="getUsuarioId == row.idusuario"
            color="primario"
            @click="onMyUser"
          >
            fa-user-circle
          </v-icon>
          <FormButtonMenu
            v-else
            icon
            :options="row.ativo ? tableOptionsDisable : tableOptionsEnable"
            :loading="loadingRow[row.idusuario]"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
    <v-dialog
      persistent
      scrollable
      v-model="dialogPermissions"
      max-width="800px"
    >
      <v-card max-height="60vh">
        <v-card-title class="pa-4">
          <span class="text-h5">
            Configurar permissões do usuário {{ permissoes.nome }}
          </span>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-4">
          <v-row>
            <v-col cols="12" md="6">
              <h4 class="text-start">Itens do menu</h4>
              <LineDivider />
              <FormCheckbox
                class="my-0 py-0 mb-n3"
                v-for="permissao in permissoes.itens_menu"
                :key="permissao.iditem"
                v-model="permissao.tem_permissao"
                :label="`${permissao.grupo} / ${permissao.nome}`"
              />
            </v-col>
            <v-col cols="12" md="6">
              <h4 class="text-start">Fornecedores</h4>
              <LineDivider />
              <FormCheckbox
                class="my-0 py-0 mb-n3"
                v-for="fornecedor in permissoes.fornecedores_granted"
                :key="fornecedor.idfornecedor"
                v-model="fornecedor.fornecedor_granted"
                :label="`${fornecedor.nome} (${fornecedor.nrdocumento})`"
              />
              <h4 class="text-start mt-10">Permissões do usuário</h4>
              <LineDivider />
              <FormCheckbox
                class="my-0 py-0 mb-n3"
                v-model="permissoes.permissao_atendente"
                label="Permitir visualizar todos os clientes"
              />
              <FormCheckbox
                class="my-0 py-0 mb-n3"
                v-model="permissoes.permissao_alterarsenha"
                label="Permitir alterar senha do cliente"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <FormButton text="Fechar" type="error" @click="onClose" />
          <FormButton text="Salvar" @click="updateUserPermission()" />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent scrollable v-model="dialogUpdate" max-width="500px">
      <v-card max-height="60vh">
        <v-card-title class="pa-4">
          <span class="text-h5">
            Alterar senha do usuário {{ permissoes.nome }}
          </span>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-4">
          <FormField
            label="Nova senha"
            v-model="form.senha"
            typeField="password"
          />
          <FormField
            label="Confirme a senha"
            v-model="form.confirmeSenha"
            typeField="password"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <FormButton text="Fechar" type="error" @click="onClose" />
          <FormButton text="Salvar" @click="updateUser('senha')" />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      scrollable
      v-model="dialogLinkCodeERP"
      max-width="500px"
    >
      <v-card max-height="60vh">
        <v-card-title class="pa-4">
          <span class="text-h5">
            Vincular código ERP ao usuário {{ permissoes.nome }}
          </span>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-4">
          <FormField
            label="Código do ERP"
            v-model="permissoes.id_usuario_erp"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <FormButton text="Fechar" type="error" @click="onClose" />
          <FormButton text="Salvar" @click="updateUser('codERP')" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import Panel from '@/components/Panel'
import LineDivider from '@/components/LineDivider'
import Chip from '@/components/Chip'
import FormButton from '@/components/form/FormButton'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import FormCheckbox from '@/components/form/FormCheckbox'
import FormField from '@/components/form/FormField'
import MarketingModules from '@/components/MarketingModules'
import { mapGetters } from 'vuex'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import {
  USUARIO_DISABLE,
  USUARIO_ENABLE,
  USUARIO_UPDATE,
  USUARIO_UPDATE_PERMISSION
} from '@/store/actions/usuario'

export default {
  name: 'UsuarioTable',
  components: {
    DataTable,
    Panel,
    Chip,
    FormButton,
    FormButtonMenu,
    FormButtonGroup,
    MarketingModules,
    FormCheckbox,
    LineDivider,
    FormField
  },
  data: () => ({
    dialogUpdate: false,
    dialogLinkCodeERP: false,
    dialogPermissions: false,
    permissoes: {},
    form: {},
    headers: [
      { text: 'Nome', value: 'nome' },
      {
        text: 'E-mail',
        value: 'nomeusuario'
      },
      {
        text: 'Empresa',
        value: 'parceiro'
      },
      {
        text: 'Ativo',
        value: 'ativo',
        align: 'center'
      },
      {
        text: 'Permissões',
        value: 'itens_menu',
        align: 'center'
      },
      {
        text: 'Id usuário ERP',
        value: 'id_usuario_erp',
        align: 'center'
      },
      {
        text: 'Opções',
        value: 'idusuario',
        clickable: false,
        align: 'center'
      }
    ],
    tableOptionsDisable: [
      { text: 'Alterar senha', value: 'update' },
      { text: 'Vincular código ERP', value: 'linkCodeERP' },
      { text: 'Desativar conta do usuário', value: 'disable' }
    ],
    tableOptionsEnable: [
      { text: 'Alterar senha', value: 'update' },
      { text: 'Vincular código ERP', value: 'linkCodeERP' },
      { text: 'Reativar conta do usuário', value: 'enable' }
    ],
    loadingRow: {},
    modulosForm: [
      ModulesEnum.PORTFOLIO_WEB,
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ]
  }),
  computed: {
    ...mapGetters(['getEmpresaId', 'getUsuarioId', 'moduloFornecedorAtivo']),
    endpoint() {
      return `/api/v4/usuario/${this.getEmpresaId}/datatable`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  methods: {
    onNew() {
      this.$router.push('usuarios/novo')
    },
    onMyUser() {
      this.$router.push('usuarios/meu-usuario')
    },
    onClose() {
      this.loadingRow = {}
      this.dialogLinkCodeERP = false
      this.dialogPermissions = false
      this.dialogUpdate = false
      this.permissoes = {}
      this.form = {}
      this.$refs.datatable.reload()
    },
    onDialogPermission(item) {
      this.dialogPermissions = true
      this.permissoes = item
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'update':
          this.permissoes = item
          return (this.dialogUpdate = true)
        case 'linkCodeERP':
          this.permissoes = item
          return (this.dialogLinkCodeERP = true)
        case 'disable':
          return this.onDisableUser(item)
        case 'enable':
          return this.onEnableUser(item)
      }
    },
    onDisableUser(item) {
      const options = {
        title: `Confirma a desativação da conta do usuário '${item.nome}'?`
      }
      this.confirmSwal(options, () => this.disableUser(item))
    },
    disableUser(item) {
      this.loadingRow = {
        ...this.loadingRow,
        [item.idusuario]: true
      }
      this.$store
        .dispatch(USUARIO_DISABLE, {
          action: 'delete',
          idusuario: item.idusuario
        })
        .then(() => {
          this.$vueOnToast.pop('success', 'Usuário desativado com sucesso!')
          this.$refs.datatable.reload()
          this.loadingRow = {
            ...this.loadingRow,
            [item.idusuario]: false
          }
        })
        .catch(err => {
          const erro = err.response || 'Erro ao atualizar usuário'
          this.$vueOnToast.pop('error', erro)
          this.loadingRow = {
            ...this.loadingRow,
            [item.idusuario]: false
          }
        })
    },
    onEnableUser(item) {
      const options = {
        title: `Confirma a reativação da conta do usuário '${item.nome}'?`
      }
      this.confirmSwal(options, () => this.enableUser(item))
    },
    enableUser(item) {
      this.loadingRow = {
        ...this.loadingRow,
        [item.idusuario]: true
      }
      this.$store
        .dispatch(USUARIO_ENABLE, {
          action: 'restore',
          idusuario: item.idusuario
        })
        .then(() => {
          this.$vueOnToast.pop('success', 'Usuário reativado com sucesso!')
          this.$refs.datatable.reload()
          this.loadingRow = {
            ...this.loadingRow,
            [item.idusuario]: false
          }
        })
        .catch(err => {
          const erro = err.response || 'Erro ao atualizar usuário'
          this.$vueOnToast.pop('error', erro)
          this.loadingRow = {
            ...this.loadingRow,
            [item.idusuario]: false
          }
        })
    },
    updateUserPermission() {
      const permissoes = {
        idusuario: this.permissoes.idusuario,
        itens: this.permissoes.itens_menu
          .filter(f => f.tem_permissao)
          .map(v => v.iditem),
        fornecedores: this.permissoes.fornecedores_granted
          .filter(f => f.fornecedor_granted)
          .map(v => v.idfornecedor),
        permissao_atendente: this.permissoes.permissao_atendente || false,
        permissao_alterarsenha: this.permissoes.permissao_alterarsenha || false
      }
      this.loadingRow = {
        ...this.loadingRow,
        [this.permissoes.idusuario]: true
      }
      this.$store
        .dispatch(USUARIO_UPDATE_PERMISSION, permissoes)
        .then(() => {
          this.$vueOnToast.pop('success', 'Permissões atualizadas com sucesso!')
          this.$refs.datatable.reload()
          this.loadingRow = {
            ...this.loadingRow,
            [this.permissoes.idusuario]: false
          }
          this.permissoes = {}
          this.dialogPermissions = false
        })
        .catch(() => {
          this.$vueOnToast.pop(
            'error',
            'Erro ao atualizar permissões do usuário'
          )
          this.loadingRow = {
            ...this.loadingRow,
            [this.permissoes.idusuario]: false
          }
        })
    },
    updateUser(dialog) {
      const data = this.prepareData(dialog)
      if (!data) return
      const msg = this.form.senha
        ? 'Senha alterada com sucesso!'
        : 'Código ERP alterado com sucesso!'
      this.loadingRow = {
        ...this.loadingRow,
        [this.permissoes.idusuario]: true
      }
      this.$store
        .dispatch(USUARIO_UPDATE, data)
        .then(() => {
          this.$vueOnToast.pop('success', msg)
          this.$refs.datatable.reload()
          this.loadingRow = {
            ...this.loadingRow,
            [this.permissoes.idusuario]: false
          }
          this.form = {}
          this.permissoes = {}
          this.dialogLinkCodeERP = false
          this.dialogUpdate = false
        })
        .catch(err => {
          const erro = err.response || 'Erro ao atualizar usuário'
          this.$vueOnToast.pop('error', erro)
          this.loadingRow = {
            ...this.loadingRow,
            [this.permissoes.idusuario]: false
          }
        })
    },
    prepareData(dialog) {
      if (dialog == 'senha') {
        if (!this.form.senha || !this.form.confirmeSenha) {
          this.$vueOnToast.pop(
            'error',
            'O campo de senha ou de confirmação da senha não foi preenchido!'
          )
          return
        }
        if (this.form.senha != this.form.confirmeSenha) {
          this.$vueOnToast.pop(
            'error',
            'Os campos de senha e de confirmação da senha não são iguais!'
          )
          return
        }
        return {
          pwd: this.form.senha,
          pwdc: this.form.confirmeSenha,
          idusuario: this.permissoes.idusuario,
          empresa: this.getEmpresaId
        }
      }
      return {
        id_usuario_erp: this.permissoes.id_usuario_erp,
        idusuario: this.permissoes.idusuario,
        empresa: this.getEmpresaId
      }
    }
  }
}
</script>
